import { flexCenter } from '@/layout/styles/classes';
import { caption } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import card from '../../assets/images/homepage/payments/card.svg';
import care from '../../assets/images/homepage/payments/care.svg';
import cash from '../../assets/images/homepage/payments/cash.svg';

const Types = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    justify-items: center;
    grid-column-gap: 17vw;
    align-content: space-between;
    align-items: stretch;
    min-height: 190px;

    > p {
        ${caption};
        ${flexCenter};
        flex-direction: column;
        color: ${({ theme }) => theme.colors.gray4};
        text-transform: capitalize;
        margin: 0;

        > img {
            margin-bottom: 16px;
        }
    }

    @media (min-width: 600px) {
        grid-template-columns: repeat(3, auto);
        grid-column-gap: 16px;
        justify-content: space-between;
        min-height: unset;
    }

    @media (min-width: 1024px) {
        margin: 0 auto;
        max-width: 1064px;
    }
`;

export const PaymentTypes = () => {
    return (
        <Types>
            <p>
                <img src={cash} alt="cash" width={90} height={48} />
                Cash
            </p>
            <p>
                <img src={card} alt="credit cards" width={75} height={48} />
                credit cards
            </p>
            <p>
                <img
                    src={care}
                    alt="care credit"
                    width={221}
                    height={30}
                    css={css`
                        width: 150px;
                        height: auto;
                        align-self: end;

                        @media (min-width: 1024px) {
                            width: 221px;
                            align-self: center;
                        }
                    `}
                />
                careCredit
            </p>
        </Types>
    );
};

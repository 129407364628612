import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { info } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { PaymentTypes } from '@/PaymentTypes';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Description = styled.p`
    margin-bottom: 24px;

    @media (min-width: 1024px) {
        margin-bottom: 64px;
    }
`;

const careCredit = css`
    margin: 24px auto !important;
    display: block;
    width: fit-content;
    border-radius: 15px;
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
    opacity: 0.9999;

    :hover,
    :focus {
        box-shadow: 0 7px 25px rgba(0, 0, 0, 0.15);
    }

    @media (min-width: 1024px) {
        margin: 64px auto !important;
    }
`;
const PaymentOptions: Page = () => (
    <Fragment>
        <Seo
            title="Payment Options | Aesthetic Bonding Cost | Auburn"
            description="Learn about our convenient payment options at Nuttall Smiles, your home for the best dentist in Auburn and surrounding areas. Book an appointment now."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/for-patients/payment-options.jpg"
                alt="payment options"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article>
            <Description>
                We offer several financial options to work with your financial situation and provide
                you with access to quality dental care in Auburn, Washington. We are in-network with
                most PPO dental insurance providers, and we will accept all insurances. For more
                information about our accepted dental insurances, please call our office to speak
                with a member of our team.
            </Description>
            <PaymentTypes />
            <CLink to="https://carecredit.com" css={careCredit}>
                <StaticImage
                    src="../../../assets/images/for-patients/link.jpg"
                    alt="apply for care credit"
                    width={353}
                    height={150}
                />
            </CLink>
            <p>
                If you have questions about our financial options or if you would like to schedule
                an appointment with our dentist Dr. Nuttall, please contact Nuttall Smiles at{' '}
                <CLink to={info.phone.link}>
                    <b>{info.phone.text}</b>
                </CLink>
                .
            </p>
        </Article>
    </Fragment>
);

export default PaymentOptions;
